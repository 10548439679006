<template>
    <v-row justify="end" class="mr-10">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5"> {{ group.id === '' ? $t('groups.add') :$t('groups.edit') }}</span>
          </v-card-title>

          <v-form v-model="valid" ref="groupForm" lazy-validation>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                  ><v-text-field
                      :rules="[...rules.required]"
                      label="Nazwa"
                      v-model='group.name'
                      autocomplete="none"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                  ><v-textarea
                      :rules="[...rules.required]"
                      label="Opis"
                      v-model='group.description'
                      autocomplete="none"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
            {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submit"
            >
            {{ $t('common.save') }}
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>
</template>

<script>
import {
  displayErrors
} from '@/utils/helpers'
export default {
  name: 'add-or-edit-group-dialog',
  data () {
    return {
      dialog: false,
      group: null,
      rules: {
        required: [v => !!v || 'Pole wymagane']
      },
      valid: true
    }
  },
  beforeMount () {
    this.init()
  },
  methods: {
    init () {
      this.group = {
        id: '',
        name: '',
        description: ''
      }
    },
    async submit () {
      if (!this.$refs.groupForm.validate()) {
        displayErrors('Uzupełnij wymagane pola lub popraw błędne wartości.')
        return
      }

      const isAdd = this.group.id === ''
      const result = await this.$http.post(isAdd
        ? '/Group/AddGroup'
        : '/Group/UpdateGroup', this.group)

      if (result.status === 200) {
        this.dialog = false
        this.$emit('submit')
      }
    }
  }
}
</script>
