<template>
    <div>
        <v-card>
            <h2 class="pl-2">
                {{ $t('groups.title')}}
            </h2>
            <v-btn
              color="blue"
              class="float-right mr-10 white--text"
              @click.stop="addOrEditGroup()"
            >
            {{ $t('common.add')}}
            <v-icon class="ml-2 white--text">
              mdi-account-multiple-plus
            </v-icon>
          </v-btn>
          <div style="clear:both"></div>
          <v-data-table
            :headers=headers
            :items=groups
          >
          <template
            slot="item.actions"
            slot-scope="props"
            class="justify-center"
            >
            <v-btn
                class="mx-2"
                icon
                @click="groupDetails(props.item)"
            ><v-icon dark>mdi-account-details</v-icon>
            </v-btn>
            <v-btn
              class="mx-2"
              icon
              @click="addOrEditGroup(props.item)"
            ><v-icon dark>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              class="mx-2"
              icon
              @click="deleteGroup(props.item)"
            ><v-icon dark>mdi-delete</v-icon>
            </v-btn>
          </template>

          </v-data-table>
        </v-card>

        <add-or-edit-group-dialog
          ref="addOrEditGroupDialog"
          @submit="getDataAsync"
        ></add-or-edit-group-dialog>

        <confirm-delete-dialog
          endPoint="/Group/DeleteGroup"
          ref="confirmDeleteDialog"
          @submit="getDataAsync"
        ></confirm-delete-dialog>
    </div>
</template>
<script>
import AddOrEditGroupDialog from '@/views/Groups/AddOrEditGroupDialog'
import ConfirmDeleteDialog from '@/components/Dialogs/ConfirmDeleteDialog'

export default {
  name: 'Groups',
  components: {
    AddOrEditGroupDialog,
    ConfirmDeleteDialog
  },

  data () {
    return {
      groups: [],
      headers: [
        { text: 'Nazwa', value: 'name' },
        { text: 'Opis', value: 'description' },
        { text: 'Akcje', value: 'actions', width: '20%', sortable: false }
      ]
    }
  },
  async beforeMount () {
    await this.getDataAsync()
  },
  methods: {
    async getDataAsync () {
      const result = await this.$http.get('/Group/GetGroups')
      this.groups = result.data
    },
    deleteGroup (group) {
      this.$refs.confirmDeleteDialog.dialog = true
      this.$refs.confirmDeleteDialog.message = group.name
      this.$refs.confirmDeleteDialog.id = group.id
    },
    addOrEditGroup (group) {
      this.$refs.addOrEditGroupDialog.dialog = true

      if (group) {
        this.$refs.addOrEditGroupDialog.group = JSON.parse(JSON.stringify(group))
      } else {
        this.$refs.addOrEditGroupDialog.init()
      }
    },
    async groupDetails (item) {
      await this.$router.push({ path: `groups-details/${item.id}` })
    }
  }
}
</script>
